import { z } from "zod";
import {
  dateStringSchema,
  dateComparisonExpSchema,
  orderBySchema,
  uuidComparisonExpSchema,
  booleanComparisonExpSchema,
} from ".";
import { plansBoolExpSchema } from "./Plans";
import { orderProductsSchema } from "./Orders";

export const subscriptionsListBoolExpSchema = z.object({
  cancellationDate: dateComparisonExpSchema.nullish(),
  paymentMethodId: uuidComparisonExpSchema.nullish(),
  id: uuidComparisonExpSchema.nullish(),
  plans: plansBoolExpSchema.nullish(),
  orgId: uuidComparisonExpSchema.nullish(),
});

export type SubscriptionsListBoolExpSchema = z.infer<
  typeof subscriptionsListBoolExpSchema
>;

export const subscriptionsListOrderBySchema = z
  .object({
    cancellationDate: orderBySchema,
  })
  .partial();

export type SubscriptionsListOrderBySchema = z.infer<
  typeof subscriptionsListOrderBySchema
>;

export const subscriptionsListAggregateOrderBySchema = z.object({
  count: orderBySchema.nullish(),
});

export type SubscriptionsListAggregateOrderBySchema = z.infer<
  typeof subscriptionsListAggregateOrderBySchema
>;

export const subscriptionsHistoryBoolExpSchema = z.object({
  expiryDate: dateComparisonExpSchema.nullish(),
  startDate: dateComparisonExpSchema.nullish(),
  orgId: uuidComparisonExpSchema.nullish(),
  cancellationDate: dateComparisonExpSchema.nullish(),
  id: uuidComparisonExpSchema.nullish(),
  isTrial: booleanComparisonExpSchema.nullish(),
  planId: uuidComparisonExpSchema.nullish(),
});

export type SubscriptionsHistoryBoolExpSchema = z.infer<
  typeof subscriptionsHistoryBoolExpSchema
>;

export const subscriptionsHistoryOrderBySchema = z.object({
  expiryDate: orderBySchema.nullish(),
  startDate: orderBySchema.nullish(),
  createdAt: orderBySchema.nullish(),
});

export type SubscriptionsHistoryOrderBySchema = z.infer<
  typeof subscriptionsHistoryOrderBySchema
>;

export const createSubscriptionPlanOneArgsSchema = z.object({
  orgId: z.string().uuid(),
  paymentMethodId: z.string().uuid(),
  planId: z.string().uuid(),
  promoId: z.string().uuid().nullish(),
  dueDate: dateStringSchema.nullish(),
  orderProducts: orderProductsSchema.array().min(0).optional(),
});

export type CreateSubscriptionPlanOneArgsSchema = z.infer<
  typeof createSubscriptionPlanOneArgsSchema
>;

export const subscriptionsHistoryInsertInputSchema = z.object({
  orgId: z.string().uuid(),
  startDate: dateStringSchema,
  expiryDate: dateStringSchema,
  orderId: z.string().uuid(),
});

export type SubscriptionsHistoryInsertInputSchema = z.infer<
  typeof subscriptionsHistoryInsertInputSchema
>;

export const SubscriptionsListSetInputSchema = z.object({
  paymentMethodId: z.string().uuid(),
});

export type SubscriptionsListSetInputSchema = z.infer<
  typeof SubscriptionsListSetInputSchema
>;

export const addAddonsSchema = z.object({
  orgId: z.string().uuid(),
  orderProducts: orderProductsSchema.array().min(1),
});

export type AddAddonsSchema = z.infer<typeof addAddonsSchema>;

export const removeAddonsSchema = z.object({
  orgId: z.string().uuid(),
  orderProducts: orderProductsSchema.array().min(1),
});

export type RemoveAddonsSchema = z.infer<typeof removeAddonsSchema>;

export const changeSubscriptionPlanArgsSchema = z.object({
  orgId: z.string().uuid(),
  planId: z.string().uuid(),
});

export type ChangeSubscriptionPlanArgsSchema = z.infer<
  typeof changeSubscriptionPlanArgsSchema
>;

export const calculateAddonsPriceDiffSchema = z.object({
  orgId: z.string().uuid(),
  isDowngrade: z.boolean(),
  orderProducts: orderProductsSchema.array().min(1),
});

export type CalculateAddonsPriceDiffSchema = z.infer<
  typeof calculateAddonsPriceDiffSchema
>;
